import { useContext } from 'react';
import { ProjectsContext } from 'src/app/providers/ProjectProvider';
import { TableContext } from 'src/app/providers/TableProvider';
import ProjectsService from 'src/shared/api/projects/ProjectsService';
import { setError } from 'src/shared/lib/utils';
import { FormType, TableSettingsForm } from 'src/shared/store/projects/types';

export const useGetForm = () => {
  const { selectedForm, setSelectedForm, setIsTableSettingsLoading, setTableSettings } =
    useContext(ProjectsContext);
  const { setTableData, resetTable } = useContext(TableContext);

  const handleSelectForm = async (form: FormType) => {
    if (selectedForm?.id === form.id) {
      resetTable();
      return;
    }

    setSelectedForm(form);
    setIsTableSettingsLoading(true);
    setTableSettings(null);
    setTableData([]);

    try {
      const { data } = await ProjectsService.getTableSettings({ formId: form.id });

      if (!data) {
        return;
      }
      const { Id, Alias, FormName, FormDescription, FormStructure } = data;

      const tableSettings: TableSettingsForm = {
        id: Id || data?.['ID'],
        formDescription: FormDescription,
        alias: Alias,
        formName: Alias,
        connection: FormName,
        formStructure: FormStructure,
      };

      setTableSettings(tableSettings);
    } catch (e) {
      setError(e.response?.data?.error || 'Ошибка загрузки формы');
    } finally {
      setIsTableSettingsLoading(false);
    }
  };

  return { handleSelectForm };
};
