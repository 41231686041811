import {
  Dispatch,
  FC,
  Fragment,
  memo,
  MouseEvent,
  SetStateAction,
  useContext,
  useEffect,
} from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { ProjectsContext } from 'src/app/providers/ProjectProvider';
import { TableContext } from 'src/app/providers/TableProvider';
import { UserContext } from 'src/app/providers/UserProvider';
import { CreateFormRes } from 'src/shared/api/projects';
import ProjectsService from 'src/shared/api/projects/ProjectsService';
import { ReactComponent as DirectoryIcon } from 'src/shared/assets/icons/kit/dictionary.svg';
import { ReactComponent as TrashIcon } from 'src/shared/assets/icons/kit/trash.svg';
import { emptyError } from 'src/shared/const';
import { setError as setGlobalError, transliterate } from 'src/shared/lib/utils';
import { FormType, TableSettingsForm } from 'src/shared/store/projects/types';
import { tableContextDefaultState } from 'src/shared/store/table/constants';
import { Button, ButtonProps, Input, Select, SelectItemType, ToolTip } from 'src/shared/ui';

import { FieldType, LocalFieldType, ProjectSettingsFormType } from '../types';

interface ProjectSettingsFormProps {
  onClose: () => void;
  setDirectoryModal: Dispatch<SetStateAction<number | null | undefined>>;
  setLocalFields: Dispatch<SetStateAction<LocalFieldType[]>>;
  localFields: LocalFieldType[];
  settings?: TableSettingsForm;
}

const defaultField = {
  removed: false,
};

export const ProjectSettingsForm: FC<ProjectSettingsFormProps> = memo(
  ({ onClose, setDirectoryModal, setLocalFields, localFields, settings }) => {
    const {
      register,
      handleSubmit,
      control,
      setValue,
      setError,
      clearErrors,
      formState: { errors, isSubmitting },
    } = useForm<ProjectSettingsFormType>({ mode: 'all' });
    const { darkMode } = useContext(UserContext);
    const { setTableSettings, setSelectedForm, forms, setForms, selectedProject } =
      useContext(ProjectsContext);
    const { setTableData } = useContext(TableContext);

    const getTypeOptions = (i: number): SelectItemType[] => [
      { value: 'text', label: 'Текст' },
      { value: 'number', label: 'Целое число' },
      { value: 'floatNumber', label: 'Дробное число' },
      { value: 'date', label: 'Дата' },
      {
        value: 'reference',
        label: 'Справочник',
        icon: (
          <DirectoryIcon
            className="h-4 w-4 fill-white hover:fill-blue-300"
            onClick={e => {
              e.stopPropagation();
              setDirectoryModal(i + 1);
              clearErrors(`fields.${i}.columnType`);
            }}
          />
        ),
      },
    ];

    useEffect(() => {
      if (!settings) {
        return handleAddRow();
      }
      setValue('formName', settings.formName);
      setValue('formDescription', settings.formDescription);
      settings.formStructure.forEach((row, i) => {
        handleAddRow(row, i);
      });
    }, [settings]);

    const handleCancel = (e: MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      onClose();
    };

    const handleAddRow = (row?: FieldType, i?: number) => {
      setLocalFields(prev => [
        ...prev,
        {
          ...defaultField,
          isNew: !row,
          ...(row?.referenceFormId && {
            dictionary: {
              referenceFormValue: row?.referenceFormValue,
              referenceFormId: row?.referenceFormId,
              referenceFormKey: row?.referenceFormKey,
            },
          }),
        },
      ]);
      const index = i || localFields.length;
      setValue(`fields.${index}.columnType`, row?.columnType || 'text');

      if (row) {
        setValue(`fields.${index}.columnName`, row.columnName);
        setValue(`fields.${index}.columnLabel`, row.columnLabel);
      }
    };

    const handleRemoveRow = (e: MouseEvent<HTMLButtonElement>, i: number) => {
      e.preventDefault();
      setLocalFields(prev =>
        prev.map((el, index) => ({ ...el, ...(i === index && { removed: true }) })),
      );
    };

    const checkNames = (fields: FieldType[]) => {
      return fields.reduce((sum, cur) => {
        if (sum[cur.columnName]) {
          sum[cur.columnName] += 1;
        } else {
          sum[cur.columnName] = 1;
        }

        return sum;
      }, {});
    };

    const onSubmit: SubmitHandler<ProjectSettingsFormType> = async ({
      formDescription,
      formName,
      fields,
    }) => {
      if (!selectedProject) {
        return;
      }

      const names = checkNames(fields);

      fields.forEach((field, i) => {
        if (names?.[field.columnName] > 1) {
          setError(`fields.${i}.columnName`, { message: 'Столбец с таким именем существует' });
        }
      });

      let error = false;
      const formStructure = fields.map((field, i) => {
        if (field.columnType === 'reference') {
          if (localFields?.[i]?.dictionary) {
            return { ...field, ...localFields[i].dictionary };
          } else {
            error = true;
            setError(`fields.${i}.columnType`, { message: emptyError });
            return field;
          }
        }

        return field;
      });

      if (error) {
        return;
      }

      try {
        let res: CreateFormRes | null = null;

        if (
          settings &&
          (settings?.formDescription !== formDescription || settings?.formName !== formName)
        ) {
          const { data } = await ProjectsService.updateForm({
            formName,
            formDescription,
            id: settings.id,
          });
          res = data;
        }

        const newRows: FieldType[] = [];
        const removeRows: FieldType[] = [];
        const editRows: FieldType[] = [];

        formStructure.forEach((row, i) => {
          const { isNew, removed } = localFields?.[i];
          if (isNew && !removed) {
            newRows.push(row);
          } else if (removed && !isNew) {
            removeRows.push(row);
          } else if (!isNew && !removed && settings) {
            const prev = settings?.formStructure?.[i];
            if (
              prev?.columnType !== row.columnType ||
              prev?.columnName !== row.columnName ||
              prev?.columnLabel !== row?.columnLabel ||
              prev?.referenceFormId !== row?.referenceFormId ||
              prev?.referenceFormKey !== row?.referenceFormKey ||
              prev?.referenceFormValue !== row?.referenceFormValue
            ) {
              editRows.push({
                ...row,
                newColumnName: row.columnName,
                columnName: prev?.columnName,
              });
            }
          }
        });

        if (newRows.length && settings) {
          const { data } = await ProjectsService.addColumnForm({
            id: settings.id,
            columns: newRows,
          });
          res = data;
        }

        if (editRows.length && settings) {
          const { data } = await ProjectsService.updateColumnForm({
            id: settings.id,
            columns: editRows,
          });
          res = data;
        }

        if (removeRows.length && settings) {
          const { data } = await ProjectsService.deleteColumnForm({
            id: settings.id,
            columns: removeRows,
          });
          res = data;
        }

        if (!settings) {
          const { data } = await ProjectsService.createForm({
            formName,
            formDescription,
            createdBy: 'admin',
            projectId: selectedProject.id,
            formStructure: formStructure.filter((_, i) => !localFields[i].removed),
          });
          res = data;
        }

        if (res) {
          const { Id, Alias, FormDescription } = res;

          const form: FormType = {
            id: Id || res?.['ID'],
            form_name: Alias || '',
            form_description: FormDescription,
          };
          const newForms = settings
            ? forms.map(el => {
                if (el.id === form.id) {
                  return form;
                }

                return el;
              })
            : [...forms, form];

          setForms(newForms);

          if (settings) {
            setTableData(tableContextDefaultState.tableData);
            setTableSettings(null);
            setSelectedForm(null);
          }

          onClose();
        }
      } catch (e) {
        if (e?.response?.data?.error) {
          return setGlobalError(e?.response?.data?.error);
        }
        setGlobalError('Не удалось сохранить изменения');
      }
    };

    const iconButtonProps: ButtonProps = {
      variant: darkMode ? 'grey' : 'greyLight',
      size: 'small',
      variable: 'icon',
      classNames: 'group w- aspect-square',
    };

    return (
      <>
        <form onSubmit={handleSubmit(onSubmit)} className="flex h-full w-full flex-col">
          <div className="flex-shrink-0 border-b border-blue-400 px-4 pb-5">
            <Input
              {...register('formName', { required: emptyError })}
              label="Название таблицы"
              placeholder="Введите название таблицы"
              error={errors.formName?.message}
              containerClassNames="mb-5"
              variant={darkMode ? 'blue' : 'grey'}
            />
            <Input
              {...register('formDescription', { required: emptyError })}
              label="Описание таблицы"
              placeholder="Введите описание таблицы"
              error={errors.formDescription?.message}
              variant={darkMode ? 'blue' : 'grey'}
            />

            {settings?.connection && (
              <Input
                label="База данных"
                value={settings.connection}
                disabled
                containerClassNames="mt-5"
                variant={darkMode ? 'blue' : 'grey'}
              />
            )}
          </div>

          <div className="mb-6 flex min-h-80 flex-1 flex-col p-4">
            <div className="font-gothampro-400 grid flex-1 auto-rows-[44px] grid-cols-7 gap-4 text-sm dark:text-white ">
              <div className="col-span-2 flex items-center justify-center">Имя</div>
              <div className="col-span-2 flex items-center justify-center">Тип</div>
              <div className="col-span-2 flex items-center justify-center">Имя в БД</div>
              <div className="flex items-center justify-center">Действие</div>
            </div>
            <div className="grid flex-1 auto-rows-[44px] grid-cols-7 gap-4 pb-4">
              {localFields.map((field, i) => (
                <Fragment key={`field-${i}`}>
                  {!field.removed && (
                    <>
                      <ToolTip label="Введите понятное и удобное для оператора название колонки. Можно использовать русские символы и пробелы">
                        <Input
                          containerClassNames="col-span-2"
                          placeholder="Введите имя"
                          error={errors.fields?.[i]?.columnLabel?.message}
                          variant={darkMode ? 'blue' : 'grey'}
                          {...register(`fields.${i}.columnLabel`, {
                            required: emptyError,
                            validate: val => {
                              if (val.length <= 25) {
                                return true;
                              }
                              return 'Максимум 25 символов';
                            },
                            onChange: e => {
                              const value = e.target.value;
                              const onlyWordsValue = value
                                .replace(/[^a-zа-щыэ-яё_&0-9 ]/gi, '')
                                .replaceAll(' ', '_');
                              const transliterateValue = transliterate(onlyWordsValue);
                              setValue(`fields.${i}.columnName`, transliterateValue);

                              return e;
                            },
                          })}
                        />
                      </ToolTip>
                      <Controller
                        render={({ field }) => (
                          <Select
                            classNames="col-span-2"
                            value={
                              getTypeOptions(i).find(
                                el => el.value === settings?.formStructure?.[i]?.columnType,
                              )?.value || getTypeOptions(i)?.[0]?.value
                            }
                            options={getTypeOptions(i)}
                            onChange={({ value }) => {
                              clearErrors(`fields.${i}.columnType`);
                              field.onChange(value);
                            }}
                            error={errors.fields?.[i]?.columnType?.message}
                          />
                        )}
                        control={control}
                        name={`fields.${i}.columnType`}
                      />
                      <ToolTip label="Введите английское наименование поля в таблице базы данных, не используйте спец.символы и пробел">
                        <Input
                          containerClassNames="col-span-2"
                          placeholder="Введите имя в БД"
                          error={errors.fields?.[i]?.columnName?.message}
                          variant={darkMode ? 'blue' : 'grey'}
                          {...register(`fields.${i}.columnName`, {
                            required: emptyError,
                            validate: val => {
                              if (/^[a-zA-Z_0-9$]+$/.test(val)) {
                                return true;
                              }
                              return 'Только символы a-z|A-Z|0-9|_-';
                            },
                          })}
                        />
                      </ToolTip>

                      <div className="flex items-center justify-center gap-1">
                        <Button {...iconButtonProps} onClick={e => handleRemoveRow(e, i)}>
                          <TrashIcon className="h-4 w-4 stroke-grey-500 group-hover:stroke-white" />
                        </Button>
                      </div>
                    </>
                  )}
                </Fragment>
              ))}
            </div>
          </div>

          <div className="flex flex-shrink-0 items-center p-4">
            <Button
              variant="violet"
              classNames="mr-3"
              onClick={e => {
                e?.preventDefault();
                handleAddRow();
              }}
            >
              Добавить строку
            </Button>
            <Button variant="green" type="submit" disabled={isSubmitting} classNames="mr-3">
              Сохранить
            </Button>
            <Button variant="greyLight" classNames="mr-3" onClick={handleCancel}>
              Отменить
            </Button>
          </div>
        </form>
      </>
    );
  },
);
