import { useContext } from 'react';
import { ProjectsContext } from 'src/app/providers/ProjectProvider';
import { TableContext } from 'src/app/providers/TableProvider';
import ProjectsService from 'src/shared/api/projects/ProjectsService';
import { setError } from 'src/shared/lib/utils';
import { ProjectType } from 'src/shared/store/projects/types';

export const useGetFormsList = () => {
  const { setForms, setIsFormsLoading, selectedProject, setSelectedProject } =
    useContext(ProjectsContext);
  const { setSelectedForm, setTableSettings } = useContext(ProjectsContext);
  const { setTableData, resetTable } = useContext(TableContext);

  const handleSelectProject = async (project: ProjectType) => {
    if (project.id === selectedProject?.id) {
      resetTable();
      return;
    }

    setSelectedForm(null);
    setTableSettings(null);
    setTableData([]);
    setSelectedProject(project);
    setIsFormsLoading(true);

    try {
      const { data } = await ProjectsService.getFormsByProjectId({ projectId: project.id });

      setForms(data || []);
    } catch (e) {
      setError(e.response?.data?.error || 'Ошибка загрузки проекта');
    } finally {
      setIsFormsLoading(false);
    }
  };

  return { handleSelectProject };
};
