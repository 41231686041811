import { FC, forwardRef, InputHTMLAttributes, ReactNode } from 'react';
import cn from 'classnames';
import { ReactComponent as ErrorIcon } from 'src/shared/assets/icons/kit/error.svg';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  prefixNode?: ReactNode;
  postfixNode?: ReactNode;
  error?: string;
  label?: string;
  sizeVariant?: 'base' | 'sm';
  containerClassNames?: string;
  inputClassNames?: string;
  variant?: 'grey' | 'blue';
}

export const Input: FC<InputProps> = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      prefixNode,
      postfixNode,
      label,
      error,
      containerClassNames,
      inputClassNames,
      sizeVariant = 'base',
      variant = 'blue',
      ...props
    },
    ref,
  ) => {
    const styles = {
      grey: cn({
        'border-pink-100 text-pink-100 placeholder:text-pink-100': Boolean(error),
        'hover:border-violet-500 focus:border-violet-500 border-violet-500': !Boolean(error),
        'hover:bg-white focus:bg-white bg-white ': !Boolean(error),
        'placeholder-shown:bg-violet-300 placeholder-shown:border-violet-400': !Boolean(error),
      }),
      blue: cn({
        'border-pink-100 text-pink-100 placeholder:text-pink-100 bg-violet-900': Boolean(error),
        'focus:border-violet-900 border-violet-900 focus:border-blue-100': !Boolean(error),
        'hover:bg-blue-1500 focus:bg-blue-1500 bg-violet-1200 hover:border-blue-100':
          !Boolean(error),
        'placeholder-shown:bg-violet-1200 placeholder-shown:border-violet-900 text-white':
          !Boolean(error),
      }),
    };

    const sizes = {
      base: 'h-11 p-4',
      sm: 'h-7 p-2',
    };

    return (
      <div
        className={cn(
          { 'w-full': !Boolean(containerClassNames?.includes('w-')) },
          'relative flex flex-col',
          containerClassNames,
        )}
      >
        {label && (
          <div className="font-gothampro-400 mb-1 ml-4 whitespace-nowrap text-xs leading-3 text-grey-300">
            {label}
          </div>
        )}

        {postfixNode && <div className="absolute bottom-0 right-0 h-11">{postfixNode}</div>}
        {prefixNode && <div className="absolute bottom-0 left-0 h-11">{prefixNode}</div>}

        {error && (
          <div
            className={cn(
              'pointer-events-none absolute bottom-0 left-0 w-full bg-pink-200 opacity-5',
              sizes[sizeVariant],
            )}
          />
        )}

        <input
          {...props}
          ref={ref}
          className={cn(
            { 'pr-11': Boolean(postfixNode), 'pl-11': Boolean(prefixNode) },
            'font-gothampro-400 w-full rounded transition-all placeholder:text-xs',
            'border transition-all',
            styles[variant],
            sizes[sizeVariant],
            inputClassNames,
          )}
        />

        {error && (
          <div className="absolute -bottom-4 flex">
            <ErrorIcon className="mr-1 h-2.5 w-2.5 fill-pink-100" />
            <div className="font-gothampro-400 text-extra-xs text-pink-100">{error}</div>
          </div>
        )}
      </div>
    );
  },
);
