import React, { Dispatch, FC, SetStateAction, useContext, useEffect, useState } from 'react';
import { ProjectsContext } from 'src/app/providers/ProjectProvider';
import { CreateFormModal } from 'src/feature/CreateFormModal';
import { CreateProjectModal } from 'src/feature/CreateProjectModal';
import { FormsList } from 'src/feature/FormsList';
import { ProjectList } from 'src/feature/ProjectList';
import { ProjectsAndFormsList } from 'src/feature/ProjectsAndFormsList';
import ProjectsService from 'src/shared/api/projects/ProjectsService';

interface SidebarProps {
  setWidth: Dispatch<SetStateAction<number>>;
}

export const Sidebar: FC<SidebarProps> = ({ setWidth }) => {
  const { setProjects, setIsProjectsLoading } = useContext(ProjectsContext);

  const getSidebarStatus = () => {
    return localStorage?.getItem('sidebar') === 'small';
  };

  const [search, setSearch] = useState<string>('');
  const [isSmall, setIsSmall] = useState<boolean>(getSidebarStatus());
  const [isCreateProjectModalOpen, setIsCreateProjectModalOpen] = useState<boolean>(false);
  const [isOpenFormModal, setIsOpenFormModal] = useState<boolean>(false);

  const handleOpenFormModal = () => setIsOpenFormModal(true);
  const handleCloseFormModal = () => setIsOpenFormModal(false);

  const handleOpenCreateProjectModal = () => setIsCreateProjectModalOpen(true);
  const handleCloseCreateProjectModal = () => setIsCreateProjectModalOpen(false);

  useEffect(() => {
    (async () => {
      try {
        const { data } = await ProjectsService.getProjectsWithSearch(search);

        setProjects(data || []);
      } catch (e) {
      } finally {
        setIsProjectsLoading(false);
      }
    })();
  }, [search]);

  return (
    <>
      <div className="relative flex flex-shrink-0 overflow-hidden shadow-lg" id="sidebar">
        {!isSmall ? (
          <ProjectsAndFormsList
            search={search}
            setWidth={setWidth}
            setSearch={setSearch}
            setIsSmall={setIsSmall}
            handleOpenFormModal={handleOpenFormModal}
            handleOpenCreateProjectModal={handleOpenCreateProjectModal}
          />
        ) : (
          <>
            <ProjectList
              setWidth={setWidth}
              setIsSmall={setIsSmall}
              setSearch={setSearch}
              handleOpenCreateProjectModal={handleOpenCreateProjectModal}
            />
            <FormsList handleOpenFormModal={handleOpenFormModal} />
          </>
        )}
      </div>

      {isCreateProjectModalOpen && <CreateProjectModal onClose={handleCloseCreateProjectModal} />}
      {isOpenFormModal && <CreateFormModal onClose={handleCloseFormModal} />}
    </>
  );
};
