import { AxiosResponse } from 'axios';
import { FieldType } from 'src/entity/ProjectSettingsForm/types';
import { ApiProjects } from 'src/shared/api/ApiProject';
import { ApiProjects2 } from 'src/shared/api/ApiProject2';
import { FormType, ProjectType } from 'src/shared/store/projects/types';

import {
  AddColumnFormParams,
  CreateFormParams,
  CreateFormRes,
  CreateProjectParams,
  CreateProjectRes,
  GetFormsByProjectIdParams,
  GetTableSettingsByFormIdParams,
  RemoveColumnFormParams,
  UpdateColumnFormParams,
  UpdateFormParams,
} from './types';

const getProjects = () => ApiProjects.get<void, Promise<AxiosResponse<ProjectType[]>>>(`/projects`);

const getProjectsWithSearch = (search: string) =>
  ApiProjects.get<void, Promise<AxiosResponse<ProjectType[]>>>(`/projects/search?search=${search}`);

const getFormsByProjectId = (params: GetFormsByProjectIdParams) =>
  ApiProjects.get<GetFormsByProjectIdParams, Promise<AxiosResponse<FormType[]>>>(
    `/forms/${params.projectId}`,
  );

const getTableSettings = (params: GetTableSettingsByFormIdParams) =>
  ApiProjects.get<GetTableSettingsByFormIdParams, Promise<AxiosResponse<CreateFormRes>>>(
    `/table/fields/${params.formId}`,
  );

const createProject = (params: CreateProjectParams) =>
  ApiProjects2.post<string, Promise<AxiosResponse<CreateProjectRes>>, CreateProjectParams>(
    `/project`,
    params,
  );

const createForm = (params: CreateFormParams) =>
  ApiProjects2.post<string, Promise<AxiosResponse<CreateFormRes>>, CreateFormParams>(
    `/form`,
    params,
  );

const updateForm = (params: UpdateFormParams) =>
  ApiProjects2.put<string, Promise<AxiosResponse<CreateFormRes>>, Omit<UpdateFormParams, 'id'>>(
    `/form/${params.id}`,
    {
      formDescription: params.formDescription,
      formName: params.formName,
    },
  );

const addColumnForm = (params: AddColumnFormParams) =>
  ApiProjects2.post<string, Promise<AxiosResponse<CreateFormRes>>, FieldType[]>(
    `/form/${params.id}/addColumn`,
    params.columns,
  );

const deleteColumnForm = (params: RemoveColumnFormParams) =>
  ApiProjects2.delete<string, Promise<AxiosResponse<CreateFormRes>>, FieldType[]>(
    `/form/${params.id}/deleteColumns`,
    {
      data: params.columns,
    },
  );

const updateColumnForm = (params: UpdateColumnFormParams) =>
  ApiProjects2.put<string, Promise<AxiosResponse<CreateFormRes>>, FieldType[]>(
    `/form/${params.id}/updateColumn`,
    params.columns,
  );

const ProjectsService = {
  getProjects,
  getProjectsWithSearch,
  getFormsByProjectId,
  createProject,
  createForm,
  getTableSettings,
  updateForm,
  addColumnForm,
  deleteColumnForm,
  updateColumnForm,
};

export default ProjectsService;
