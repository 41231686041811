import React, {
  ChangeEvent,
  Dispatch,
  FC,
  memo,
  SetStateAction,
  useContext,
  useState,
} from 'react';
import { ProjectsContext } from 'src/app/providers/ProjectProvider';
import { TableContext } from 'src/app/providers/TableProvider';
import { CreateFormModal } from 'src/feature/CreateFormModal';
import { Table } from 'src/feature/Table';
import { TableInfo } from 'src/feature/TableInfo';
import { ReactComponent as InfoCircleIcon } from 'src/shared/assets/icons/kit/info-circle.svg';
import { ReactComponent as LoupeIcon } from 'src/shared/assets/icons/kit/loupe.svg';
import { debounce } from 'src/shared/lib/utils';
import { UserContext } from 'src/shared/store/user';
import { Button, ToolTip } from 'src/shared/ui';
import { Loader } from 'src/shared/ui/Loader';
import { HeadItems } from 'src/widgets/ProjectTable/components/HeadItems';
import { TableButtons } from 'src/widgets/ProjectTable/components/TableButtons';

import 'tippy.js/dist/tippy.css';

interface ProjectTableProps {
  fullWidth: boolean;
  width: number;
  setFullWidth: Dispatch<SetStateAction<boolean>>;
}

export const ProjectTablePage: FC<ProjectTableProps> = memo(
  ({ fullWidth, width, setFullWidth }) => {
    const { tableSettings, isTableSettingsLoading, selectedProject, forms } =
      useContext(ProjectsContext);
    const { setSearch, isEdit } = useContext(TableContext);
    const { user } = useContext(UserContext);

    const [isInfoVisible, setIsInfoVisible] = useState(false);
    const [isOpenFormModal, setIsOpenFormModal] = useState<boolean>(false);

    const handleOpenFormModal = () => setIsOpenFormModal(true);
    const handleCloseFormModal = () => setIsOpenFormModal(false);

    const handleOpenInfo = () => setIsInfoVisible(true);
    const handleCloseInfo = () => setIsInfoVisible(false);

    const handleSearch = debounce((e: ChangeEvent<HTMLInputElement>) => {
      if (!user?.isDeveloper) {
        setSearch(e.target.value);
      }
    }, 500);

    const name = `${selectedProject?.project_name || ''}/${tableSettings?.alias || ''}`;

    return (
      <>
        <div className="relative flex h-full w-full flex-col px-2.5 py-5">
          <HeadItems fullWidth={fullWidth} setFullWidth={setFullWidth} />

          {!tableSettings && !isTableSettingsLoading && (
            <div className="relative flex h-full w-full flex-col items-center justify-center">
              {selectedProject ? (
                !isTableSettingsLoading ? (
                  forms?.length ? (
                    <div className="font-gothampro-500 text-lg">Форма не выбрана</div>
                  ) : (
                    <>
                      <div className="font-gothampro-500 mb-10 text-lg">
                        В настоящий момент в проекте нет актуальных таблиц.
                      </div>
                      <Button variant="green" classNames="w-40" onClick={handleOpenFormModal}>
                        Создать
                      </Button>
                    </>
                  )
                ) : (
                  <Loader classNames="w-40 h-40" />
                )
              ) : (
                <>
                  <div className="font-gothampro-500 text-lg">Проект не выбран</div>
                </>
              )}
            </div>
          )}

          {tableSettings && (
            <>
              <div className="relative mb-6 flex flex-shrink-0 items-center pl-16">
                <ToolTip label={name} checkWidth>
                  <div className="font-gothampro-500 max-w-1/2 ml-4 flex truncate text-lg">
                    {name}
                  </div>
                </ToolTip>
                <InfoCircleIcon
                  className="ml-2.5 h-6 w-6 cursor-pointer fill-violet-1300"
                  onClick={handleOpenInfo}
                />

                {isInfoVisible && (
                  <div className="absolute left-20 top-0 z-10 w-[38rem]">
                    <TableInfo onClose={handleCloseInfo} />
                  </div>
                )}
              </div>

              <div className="mb-4 flex flex-shrink-0 items-center">
                <div className="mr-5 flex h-11 w-2/3 items-center rounded bg-white pl-4 pr-2.5">
                  <input className="w-full text-sm" placeholder="Поиск" onChange={handleSearch} />
                  <LoupeIcon className="ml-auto h-4 w-4 min-w-[1rem] fill-grey-800" />
                </div>

                {!user?.isDeveloper && isEdit && <TableButtons />}
              </div>

              <div className="flex-1">
                <Table
                  tableSettings={tableSettings}
                  height={`calc(100dvh - 186px)`}
                  width={fullWidth ? '100%' : `calc(100dvw - ${width + 20}px)`}
                  setFullWidth={setFullWidth}
                />
              </div>
            </>
          )}
        </div>

        {isOpenFormModal && <CreateFormModal onClose={handleCloseFormModal} />}
      </>
    );
  },
);
