import React, { ChangeEvent, FC, useContext, useState } from 'react';
import { ProjectsContext } from 'src/app/providers/ProjectProvider';
import { TableContext } from 'src/app/providers/TableProvider';
import TableService from 'src/shared/api/table/TableService';
import { setError, setSuccess } from 'src/shared/lib/utils';
import { Button, Modal } from 'src/shared/ui';

interface ProjectImportDataModalProps {
  onClose: () => void;
}

export const ProjectImportDataModal: FC<ProjectImportDataModalProps> = ({ onClose }) => {
  const { resetTable, tableData } = useContext(TableContext);
  const { selectedForm } = useContext(ProjectsContext);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [file, setFile] = useState<File | null>(null);

  const handleClose = () => onClose();

  const handleChangeFile = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e?.target?.files?.length) {
      return;
    }
    setFile(e.target.files[0]);
  };

  const handleImport = async () => {
    if (!file || !selectedForm?.id) {
      return;
    }

    try {
      setIsLoading(true);
      const formData = new FormData();

      formData.set('file', file, file.name);
      formData.set('table_id', selectedForm.id.toString());
      formData.set('selected_row', ((tableData?.length || 0) + 1).toString());

      await TableService.importExcel(selectedForm.id, formData);

      setSuccess('Файл успешно загружен');
      resetTable();
    } catch (e) {
      setError(e?.response?.data?.error || 'Ошибка');
    } finally {
      setIsLoading(false);
      setFile(null);
      handleClose();
    }
  };

  return (
    <Modal onClose={handleClose}>
      <div className="flex w-[50rem] flex-col items-center">
        <div className="font-gothampro-500 mb-10 w-fit text-2xl text-green-100">Импорт</div>
        <div className="font-gothampro-400 mb-10 dark:text-white">
          {file ? file.name : 'Файл не выбран'}
        </div>
        <div className="flex gap-4">
          <Button
            variant="green"
            classNames="w-48"
            disabled={isLoading || !file}
            onClick={handleImport}
          >
            Загрузить
          </Button>
          <label>
            <div
              className={
                'font-gothampro-400 flex h-11 w-48 items-center justify-center rounded ' +
                'cursor-pointer bg-violet-100 px-5 text-white transition-all hover:bg-violet-200'
              }
            >
              Добавить файл
            </div>
            <input
              type="file"
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              className="hidden"
              onChange={handleChangeFile}
              multiple={false}
            />
          </label>
          <Button variant="grey" onClick={handleClose} classNames="w-48" disabled={isLoading}>
            Отмена
          </Button>
        </div>
      </div>
    </Modal>
  );
};
