import { FC, useContext, useEffect, useState } from 'react';
import { ProjectsContext } from 'src/app/providers/ProjectProvider';
import { FieldType } from 'src/entity/ProjectSettingsForm/types';
import ProjectsService from 'src/shared/api/projects/ProjectsService';
import { FormDirectoryType } from 'src/shared/store/projects/types';
import { Button, Select, SelectItemType } from 'src/shared/ui';

interface ProjectSettingDirectoryFormProps {
  index: number;
  onSave: (i: number, data: FormDirectoryType) => void;
  onClose: () => void;
  directory?: FormDirectoryType;
}

export const ProjectSettingDirectoryForm: FC<ProjectSettingDirectoryFormProps> = ({
  onSave,
  index,
  directory,
  onClose,
}) => {
  const { forms } = useContext(ProjectsContext);

  const [data, setData] = useState<FormDirectoryType | null>(directory || null);
  const [settings, setSettings] = useState<FieldType[]>([]);

  const formsList: SelectItemType[] = forms.map(({ form_name, id }) => ({
    label: form_name,
    value: id.toString(),
  }));

  useEffect(() => {
    if (!forms.length) {
      return;
    }

    (async () => {
      const { data } = await ProjectsService.getTableSettings({
        formId: directory?.referenceFormId || forms[0].id,
      });
      const list = data.FormStructure.filter(
        el => !(el.columnName !== 'id' && el.columnType === 'reference'),
      );
      setSettings(list);

      !directory &&
        setData({
          referenceFormId: forms[0].id,
          referenceFormKey: list?.[0]?.columnName,
          referenceFormValue: list?.[0]?.columnName,
        });
    })();
  }, [forms, directory]);

  const handleChangeTable = async (value: SelectItemType) => {
    const { data } = await ProjectsService.getTableSettings({ formId: +value.value });

    const list = data.FormStructure.filter(
      el => !(el.columnName !== 'id' && el.columnType === 'reference'),
    );
    setSettings(list);

    setData({
      referenceFormId: +value.value,
      referenceFormKey: list?.[0]?.columnName,
      referenceFormValue: list?.[0]?.columnName,
    });
  };

  return (
    <>
      <div className="font-gothampro-500 mb-8 text-center text-xl dark:text-white">Справочник</div>

      {forms.length ? (
        <div className="mb-12 grid w-[40rem] flex-1 auto-rows-[44px] grid-cols-3 gap-4">
          <div className="font-gothampro-400 flex items-center justify-center text-sm dark:text-white">
            Таблица
          </div>
          <div className="font-gothampro-400 flex items-center justify-center text-sm dark:text-white">
            Ключ
          </div>
          <div className="font-gothampro-400 flex items-center justify-center text-sm dark:text-white">
            Значение
          </div>
          <Select
            classNames=""
            value={data?.referenceFormId?.toString()}
            options={formsList}
            onChange={handleChangeTable}
          />
          {settings.length ? (
            <>
              <Select
                classNames=""
                value={data?.referenceFormKey}
                options={settings?.map(({ columnName, columnLabel }) => ({
                  value: columnName,
                  label: columnLabel || 'ID',
                }))}
                onChange={({ value }) => {
                  setData(prev => ({ ...prev, referenceFormKey: value }));
                }}
              />
              <Select
                classNames=""
                value={data?.referenceFormValue}
                options={settings?.map(({ columnName, columnLabel }) => ({
                  value: columnName,
                  label: columnLabel || 'ID',
                }))}
                onChange={({ value }) => {
                  setData(prev => ({ ...prev, referenceFormValue: value }));
                }}
              />
            </>
          ) : null}
        </div>
      ) : (
        <div className="font-gothampro-400 mb-12 text-center text-white">
          В текущем проекте нет форм
        </div>
      )}

      <div className="flex justify-center gap-2.5">
        {forms.length && data ? (
          <Button variant="green" classNames="w-52" onClick={() => onSave(index, data)}>
            Сохранить
          </Button>
        ) : null}
        <Button variant="greyLight" classNames="w-52" onClick={onClose}>
          Отменить
        </Button>
      </div>
    </>
  );
};
