import { Store } from 'react-notifications-component';
import { transliterateDictionary } from 'src/shared/const';

export const isEmptyObject = (obj: Object) => {
  for (let i in obj) {
    if (obj.hasOwnProperty(i)) {
      return false;
    }
  }
  return true;
};

export const isEmptyDataObject = (obj: Object) => {
  for (let i in obj) {
    if (obj[i]) {
      return false;
    }
  }
  return true;
};

export const saveByteArray = (function () {
  const a = document.createElement('a');
  document.body.appendChild(a);
  a.style.display = 'none';
  return (data: BlobPart, name: string) => {
    const blob = new Blob([data], { type: 'octet/stream' });
    const url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = name;
    a.click();
    window.URL.revokeObjectURL(url);
  };
})();

export const sleep = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

export const declOfNum = (number: number, words: string[]): string =>
  words[
    number % 100 > 4 && number % 100 < 20
      ? 2
      : [2, 0, 1, 1, 1, 2][number % 10 < 5 ? Math.abs(number) % 10 : 5]
  ];

export const includesCN = (cn: string = '', checkCN: string): boolean =>
  !cn ? false : cn.includes(checkCN);

export const getRandomInt = (max: number): number => Math.floor(Math.random() * max);

export const debounce = (fn: (...args: any[]) => void, ms = 300) => {
  let timeoutId: ReturnType<typeof setTimeout>;
  return function (this: any, ...args: any[]) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => fn.apply(this, args), ms);
  };
};

export const isValidDate = (d: Date): boolean => !isNaN(d.getTime());

export const isTimeStamp = (milliSeconds: string | number): boolean =>
  Number(milliSeconds) ? new Date(Number(milliSeconds)).getTime() > 0 : false;

const noticeTitles = {
  danger: 'Ошибка',
  success: 'Успех',
};

const setNotice = (message: string, type: 'danger' | 'success', title?: string) =>
  Store.addNotification({
    title: title || noticeTitles[type],
    message,
    type,
    insert: 'top',
    container: 'top-right',
    animationIn: ['animate__animated', 'animate__fadeIn'],
    animationOut: ['animate__animated', 'animate__fadeOut'],
    dismiss: {
      duration: 4000,
      onScreen: true,
    },
  });
export const setSuccess = (message: string) => setNotice(message, 'success');

export const setError = (message: string) => setNotice(message, 'danger');

export const transliterate = (word: string): string => {
  return word
    .split('')
    .map(char => transliterateDictionary[char] || char)
    .join('');
};
