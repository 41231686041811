import { FC, ReactNode, useRef } from 'react';
import cn from 'classnames';
import { ReactComponent as CloseIcon } from 'src/shared/assets/icons/kit/close.svg';
import { useClickOutside } from 'src/shared/lib/hooks/useClickOutside';

interface ModalProps {
  onClose: () => void;
  classNames?: string;
  bgClassNames?: string;
  children: ReactNode;
}

export const Modal: FC<ModalProps> = ({ onClose, children, classNames, bgClassNames }) => {
  const ref = useRef<HTMLDivElement>(null);

  useClickOutside(ref, onClose);

  return (
    <div
      className={cn(
        { 'z-130': !Boolean(classNames?.includes('z-')) },
        'fixed inset-0 flex h-dvh w-dvw items-center justify-center',
        classNames,
      )}
    >
      <div
        className={cn(
          { 'overflow-auto': !classNames?.includes('overflow-') },
          'relative max-h-dvh rounded-lg bg-white-100 px-36 py-12 dark:shadow-pink-xl',
          'shadow-white-xl dark:bg-violet-1100',
        )}
        ref={ref}
      >
        <button
          className="absolute right-5 top-5 flex h-5 w-5 items-center justify-center"
          onClick={onClose}
        >
          <CloseIcon className="h-4 w-4 dark:stroke-white" />
        </button>
        {children}
      </div>
    </div>
  );
};
