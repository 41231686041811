import { IUserContext } from 'src/shared/store/user/types';

export enum userRolesEnum {
  ADMIN = 'admin',
  OPERATOR = 'data_operator',
  DEVELOPER = 'form_developer',
}

export const userContextDefaultState: IUserContext = {
  user: null,
  setUser: () => {},
  isUserLoading: true,
  setIsUserLoading: () => {},
  darkMode: true,
  setDarkMode: () => {},
};
