import { Dispatch, SetStateAction, useContext } from 'react';
import { CellLocation, Id, MenuOption, SelectionMode } from '@silevis/reactgrid';
import { ProjectsContext } from 'src/app/providers/ProjectProvider';
import { TableContext } from 'src/app/providers/TableProvider';
import { UserContext } from 'src/app/providers/UserProvider';
import TableService from 'src/shared/api/table/TableService';
import { saveByteArray, setError } from 'src/shared/lib/utils';

interface UseContextMenuProps {
  isIdHidden: boolean;
  setIsIdHidden: Dispatch<SetStateAction<boolean>>;
  setIsOpenEditModal: Dispatch<SetStateAction<boolean>>;
  setIsOpenImportModal: Dispatch<SetStateAction<boolean>>;
}

export const useContextMenu = ({
  isIdHidden,
  setIsIdHidden,
  setIsOpenEditModal,
  setIsOpenImportModal,
}: UseContextMenuProps) => {
  const { setIsEdit, addEmptyRows, addEmptyTopRows, isEdit, setTableData } =
    useContext(TableContext);
  const { selectedForm } = useContext(ProjectsContext);
  const { user } = useContext(UserContext);

  const handleExport = async () => {
    if (!selectedForm?.id) {
      return;
    }

    try {
      const { data } = await TableService.exportExcel(selectedForm?.id);

      saveByteArray(data, 'table.xlsx');
    } catch (e) {
      setError(e.response?.data?.error || 'Ошибка экспорта');
    }
  };

  const editMenu = (rows: number[]) => [
    {
      id: 'addRowBottom',
      label: 'Добавить строку снизу',
      handler: () => addEmptyRows(1),
    },
    {
      id: 'addRowTop',
      label: 'Добавить строку сверху',
      handler: () => addEmptyTopRows(),
    },
    {
      id: 'removeRow',
      label: `Удалить ${rows.length > 1 ? 'строки' : 'строку'}`,
      handler: () => {
        if (rows.length) {
          setTableData(prev => prev.filter((_, i) => !rows.includes(i)));
        }
      },
    },
  ];

  const mainMenu = [
    {
      id: 'edit',
      label: 'Редактировать значения',
      handler: () => setIsEdit(prev => !prev),
    },
    {
      id: 'id',
      label: isIdHidden ? 'Показать ID' : 'Скрыть ID',
      handler: () => setIsIdHidden(prev => !prev),
    },
    {
      id: 'import',
      label: 'Импорт из Excel',
      handler: () => setIsOpenImportModal(prev => !prev),
    },
    {
      id: 'export',
      label: 'Экспорт в Excel',
      handler: handleExport,
    },
  ];

  const developerMenu = [
    {
      id: 'editTable',
      label: 'Редактровать форму',
      handler: () => setIsOpenEditModal(true),
    },
  ];

  const getMainMenu = (): MenuOption[] => {
    if (!user?.isAdmin) return mainMenu;

    return [...developerMenu, ...mainMenu];
  };

  const simpleHandleContextMenu = (
    selectedRowIds: Id[],
    selectedColIds: Id[],
    selectionMode: SelectionMode,
    menuOptions: MenuOption[],
    selectedRanges: Array<CellLocation[]>,
  ): MenuOption[] => {
    if (user?.isDeveloper) {
      return developerMenu;
    }

    if (isEdit) {
      const selectedRows: number[] =
        [...new Set(selectedRanges?.[0]?.map(row => row.rowId as number))] || [];

      return editMenu(selectedRows);
    }

    return getMainMenu();
  };

  return { simpleHandleContextMenu };
};
