import { FC, useContext } from 'react';
import cn from 'classnames';
import { UserContext } from 'src/app/providers/UserProvider';
import { ReactComponent as MoonIcon } from 'src/shared/assets/icons/kit/moon.svg';
import { ReactComponent as SunIcon } from 'src/shared/assets/icons/kit/sun.svg';

interface DarkModeSwitchProps {
  classNames?: string;
}

export const DarkModeSwitch: FC<DarkModeSwitchProps> = ({ classNames }) => {
  const { setDarkMode, darkMode } = useContext(UserContext);

  const handleDarkMode = () => setDarkMode(prev => !prev);

  return (
    <button
      className={cn(
        { relative: !Boolean(classNames?.includes('absolute')) },
        'flex h-8 rounded-full bg-grey-1300 p-0.5 shadow-black-xl',
        classNames,
      )}
      onClick={handleDarkMode}
    >
      <div
        className={cn(
          { 'left-0.5': !darkMode, 'left-8': darkMode },
          'absolute left-0.5 top-0.5 h-7 w-7 rounded-full bg-violet-1300 transition-all',
        )}
      />

      <div className="relative mr-0.5 flex h-7 w-7 items-center justify-center">
        <SunIcon
          className={cn({ 'fill-grey-400': darkMode, 'fill-yellow-100': !darkMode }, 'h-4 w-4')}
        />
      </div>
      <div className="relative flex h-7 w-7 items-center justify-center">
        <MoonIcon
          className={cn({ 'fill-grey-400': !darkMode, 'fill-yellow-100': darkMode }, 'h-4 w-4')}
        />
      </div>
    </button>
  );
};
