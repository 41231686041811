import { AxiosResponse } from 'axios';
import { ApiProjects } from 'src/shared/api/ApiProject';

import { BatchRowsData, BatchRowsParams, BatchRowsRes } from './types';

const batchRows = (params: BatchRowsParams) =>
  ApiProjects.post<string, Promise<AxiosResponse<BatchRowsRes>>, BatchRowsData>(
    `/table/${params.table_id}/batch`,
    params.data,
  );

const importExcel = (tableId: number, params: FormData) =>
  ApiProjects.post<string, string, FormData>(`/table/${tableId}/parse`, params, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });

const exportExcel = (tableId: number) => ApiProjects.post(`/table/${tableId}/export`, { tableId });

const TableService = { batchRows, importExcel, exportExcel };

export default TableService;
