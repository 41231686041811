import React, { createContext, FC, ReactNode, useState } from 'react';
import { projectsContextDefaultState } from 'src/shared/store/projects/constants';
import {
  FormType,
  IProjectsContext,
  ProjectType,
  TableSettingsForm,
} from 'src/shared/store/projects/types';

export const ProjectsContext = createContext<IProjectsContext>(projectsContextDefaultState);

interface IUserProviderProps {
  children: ReactNode;
}

export const ProjectsProvider: FC<IUserProviderProps> = ({ children }) => {
  const {
    projects: _projects,
    isProjectsLoading: _isProjectsLoading,

    forms: _forms,
    isFormsLoading: _isFormsLoading,

    tableSettings: _tableSettings,
    isTableSettingsLoading: _isTableSettingsLoading,

    selectedProject: _selectedProject,
    selectedForm: _selectedForm,
  } = projectsContextDefaultState;

  const [projects, setProjects] = useState<ProjectType[]>(_projects);
  const [isProjectsLoading, setIsProjectsLoading] = useState<boolean>(_isProjectsLoading);

  const [tableSettings, setTableSettings] = useState<TableSettingsForm | null>(_tableSettings);
  const [isTableSettingsLoading, setIsTableSettingsLoading] =
    useState<boolean>(_isTableSettingsLoading);

  const [forms, setForms] = useState<FormType[]>(_forms);
  const [isFormsLoading, setIsFormsLoading] = useState<boolean>(_isFormsLoading);

  const [selectedProject, setSelectedProject] = useState<ProjectType | null>(_selectedProject);
  const [selectedForm, setSelectedForm] = useState<FormType | null>(_selectedForm);

  const handleSetTableSettings = (setting: TableSettingsForm | null) => {
    if (!setting) {
      return setTableSettings(null);
    }

    const formStructure = setting?.formStructure?.map(el => ({
      ...el,
      columnType:
        el.columnType === 'reference'
          ? el.columnName.toLowerCase() === 'id'
            ? 'number'
            : 'dropdown'
          : el.columnType,
    }));

    setTableSettings({
      ...setting,
      formStructure,
    });
  };

  return (
    <ProjectsContext.Provider
      value={{
        projects,
        setProjects,
        isProjectsLoading,
        setIsProjectsLoading,

        forms,
        setForms,
        isFormsLoading,
        setIsFormsLoading,

        tableSettings,
        setTableSettings: handleSetTableSettings,
        isTableSettingsLoading,
        setIsTableSettingsLoading,

        selectedProject,
        setSelectedProject,

        selectedForm,
        setSelectedForm,
      }}
    >
      {children}
    </ProjectsContext.Provider>
  );
};
