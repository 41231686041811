import React, { FC, useContext, useMemo } from 'react';
import cn from 'classnames';
import { ProjectsContext } from 'src/app/providers/ProjectProvider';
import { useGetForm } from 'src/shared/lib/hooks/useGetForm';
import { UserContext } from 'src/shared/store/user';
import { Button } from 'src/shared/ui';
import { Loader } from 'src/shared/ui/Loader';

interface FormsListProps {
  isSelected: boolean;
  search: string;
  handleOpenFormModal: () => void;
}

export const FormsList: FC<FormsListProps> = ({ search, isSelected, handleOpenFormModal }) => {
  const { forms, isFormsLoading, selectedForm } = useContext(ProjectsContext);
  const { user, darkMode } = useContext(UserContext);

  const { handleSelectForm } = useGetForm();

  const filteredForms = useMemo(() => {
    if (!search) {
      return forms;
    }

    return forms.filter(({ form_name }) => form_name.toLowerCase().includes(search.toLowerCase()));
  }, [search, forms]);

  return (
    <div className="mb-4">
      {isSelected && isFormsLoading && (
        <div className="flex h-28 w-full items-center justify-center">
          <Loader classNames="w-10 h-10" />
        </div>
      )}

      {isSelected && !isFormsLoading && (
        <div className="w-full">
          {!filteredForms?.length && (
            <div className={'flex h-14 min-h-14 w-full items-center justify-center'}>
              <div className="font-gothampro-400 tex-sm dark:text-white">Формы не найдены</div>
            </div>
          )}

          {filteredForms.map(form => (
            <div
              key={`form-${form.id}`}
              className={cn(
                {
                  'opacity-100': form.id === selectedForm?.id,
                  'opacity-50 hover:opacity-100': form.id !== selectedForm?.id,
                },
                'ml-5 flex h-10 min-h-10 w-64 cursor-pointer items-center',
              )}
              onClick={() => handleSelectForm(form)}
            >
              <div className="font-gothampro-400 tex-sm truncate text-black dark:text-white">
                {form.form_name}
              </div>
            </div>
          ))}

          {!user?.isOperator && (
            <div className="pr-1">
              <Button
                variant={darkMode ? 'violetDark' : 'violetOutline'}
                onClick={handleOpenFormModal}
              >
                Добавить форму
              </Button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
