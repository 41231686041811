import React, { FC, memo, useContext, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';
import { ProjectsContext } from 'src/app/providers/ProjectProvider';
import { ProjectSettingDirectoryForm } from 'src/entity/ProjectSettingDirectoryForm';
import { FieldVariantsType, LocalFieldType } from 'src/entity/ProjectSettingsForm/types';
import { ProjectSettingsForm } from 'src/entity/ProjectSettingsForm/ui';
import { FormDirectoryType } from 'src/shared/store/projects/types';
import { StateActionType } from 'src/shared/types';
import { Modal } from 'src/shared/ui';

interface ProjectSettingsModalProps {
  setIsOpenEditModal: StateActionType<boolean>;
}

export const ProjectSettingsModal: FC<ProjectSettingsModalProps> = memo(
  ({ setIsOpenEditModal }) => {
    const [directoryModal, setDirectoryModal] = useState<number | null>();
    const [localFields, setLocalFields] = useState<LocalFieldType[]>([]);

    const onClose = () => setIsOpenEditModal(false);

    const { tableSettings } = useContext(ProjectsContext);

    const addDirectory = (i: number, data: FormDirectoryType) => {
      setDirectoryModal(null);
      setLocalFields(prev =>
        prev.map((el, index) => ({ ...el, ...(i === index && { dictionary: data }) })),
      );
    };

    const settings = useMemo(
      () =>
        tableSettings
          ? {
              ...tableSettings,
              formStructure: tableSettings?.formStructure
                .filter(el => el.columnType !== 'actions' && el.columnName.toLowerCase() !== 'id')
                .map(el => ({
                  ...el,
                  columnType: el.columnType.replace('dropdown', 'reference') as FieldVariantsType,
                })),
            }
          : undefined,
      [tableSettings],
    );

    return (
      <>
        <Modal
          onClose={() => {
            if (directoryModal) return;
            onClose();
          }}
        >
          <div className="flex w-[50rem] flex-col items-center">
            <div className="font-gothampro-500 mb-5 w-fit text-xl text-green-100">
              Редактирование проекта
            </div>

            <ProjectSettingsForm
              setDirectoryModal={setDirectoryModal}
              localFields={localFields}
              setLocalFields={setLocalFields}
              onClose={onClose}
              settings={settings}
            />
          </div>
        </Modal>

        {directoryModal &&
          createPortal(
            <Modal classNames="overflow-" onClose={() => setDirectoryModal(null)}>
              <ProjectSettingDirectoryForm
                onSave={addDirectory}
                index={directoryModal - 1}
                directory={localFields[directoryModal - 1]?.dictionary}
                onClose={() => setDirectoryModal(null)}
              />
            </Modal>,
            document.body,
          )}
      </>
    );
  },
);
