import { FC, ReactNode } from 'react';
import { DarkModeSwitch } from 'src/entity/DarkModeSwitch';

interface PublicRouteProps {
  children: ReactNode;
}

export const PublicRoute: FC<PublicRouteProps> = ({ children }) => {
  return (
    <>
      {children}
      <DarkModeSwitch classNames="absolute top-4 left-4" />
    </>
  );
};
