import * as React from 'react';
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import Select, { CSSObjectWithLabel, MenuProps, OptionProps } from 'react-select';
import {
  Cell,
  CellTemplate,
  Compatible,
  getCellProperty,
  getCharFromKey,
  isAlphaNumericKey,
  keyCodes,
  Uncertain,
  UncertainCompatible,
} from '@silevis/reactgrid';

export type OptionType = {
  label: string;
  value: string;
  isDisabled?: boolean;
};

export interface DropdownCell extends Cell {
  type: 'dropdown';
  selectedValue?: string;
  values: OptionType[];
  isDisabled?: boolean;
  selectOpen?: string;
  selectKey?: string;
  inputValue?: string;
  styles?: {
    container?: CSSObjectWithLabel;
    control?: CSSObjectWithLabel;
    indicatorsContainer?: CSSObjectWithLabel;
    dropdownIndicator?: CSSObjectWithLabel;
    singleValue?: CSSObjectWithLabel;
    indicatorSeparator?: CSSObjectWithLabel;
    input?: CSSObjectWithLabel;
    valueContainer?: CSSObjectWithLabel;
  };
}

export class DropdownCellTemplate implements CellTemplate<DropdownCell> {
  getCompatibleCell(uncertainCell: Uncertain<DropdownCell>): Compatible<DropdownCell> {
    let selectedValue: string | undefined;

    try {
      selectedValue = getCellProperty(uncertainCell, 'selectedValue', 'string');
    } catch {
      selectedValue = undefined;
    }

    const values = getCellProperty(uncertainCell, 'values', 'object');
    const value = selectedValue ? parseFloat(selectedValue) : NaN;

    let isDisabled = true;
    try {
      isDisabled = getCellProperty(uncertainCell, 'isDisabled', 'boolean');
    } catch {
      isDisabled = false;
    }

    let inputValue: string | undefined;
    try {
      inputValue = getCellProperty(uncertainCell, 'inputValue', 'string');
    } catch {
      inputValue = undefined;
    }

    let selectOpen: string | undefined;
    try {
      selectOpen = getCellProperty(uncertainCell, 'selectOpen', 'string');
    } catch {
      selectOpen = undefined;
    }

    let selectKey: string | undefined;
    try {
      selectKey = getCellProperty(uncertainCell, 'selectKey', 'string');
    } catch {
      selectKey = undefined;
    }

    const text = selectedValue || '';

    return {
      ...uncertainCell,
      selectedValue,
      text,
      value,
      values,
      isDisabled,
      selectOpen,
      selectKey,
      inputValue,
    };
  }

  update(
    cell: Compatible<DropdownCell>,
    cellToMerge: UncertainCompatible<DropdownCell>,
  ): Compatible<DropdownCell> {
    let selectedValueFromText: string | undefined = undefined;
    const findByLabel = cell.values.find((val: any) => val.label === cellToMerge.text);

    if (findByLabel) {
      selectedValueFromText = findByLabel.value;
    }

    if (!findByLabel && cell.values.some((val: any) => val.value === cellToMerge.text)) {
      selectedValueFromText = cellToMerge.text;
    }

    return this.getCompatibleCell({
      ...cell,
      selectedValue: selectedValueFromText,
      selectOpen: cellToMerge.selectOpen,
      inputValue: cellToMerge.inputValue,
    });
  }

  getClassName(cell: Compatible<DropdownCell>, isInEditMode: boolean): string {
    const isOpen = cell.selectOpen === cell.selectKey ? 'open' : 'closed';
    return `${cell.className ? cell.className : ''}${isOpen}`;
  }

  handleKeyDown(
    cell: Compatible<DropdownCell>,
    keyCode: number,
    ctrl: boolean,
    shift: boolean,
    alt: boolean,
    key: string,
    capsLock: boolean,
  ): {
    cell: Compatible<DropdownCell>;
    enableEditMode: boolean;
  } {
    if ((keyCode === keyCodes.SPACE || keyCode === keyCodes.ENTER) && !shift) {
      return {
        cell: this.getCompatibleCell({
          ...cell,
          selectOpen: cell.selectOpen === cell.selectKey ? undefined : cell.selectKey,
        }),
        enableEditMode: false,
      };
    }

    const char = getCharFromKey(key, shift, capsLock);

    if (!ctrl && !alt && isAlphaNumericKey(keyCode) && !(shift && keyCode === keyCodes.SPACE))
      return {
        cell: this.getCompatibleCell({
          ...cell,
          inputValue: char,
          selectOpen: cell.selectOpen === cell.selectKey ? undefined : cell.selectKey,
        }),
        enableEditMode: false,
      };

    return { cell, enableEditMode: false };
  }

  handleCompositionEnd(
    cell: Compatible<DropdownCell>,
    eventData: any,
  ): {
    cell: Compatible<DropdownCell>;
    enableEditMode: boolean;
  } {
    return {
      cell: {
        ...cell,
        inputValue: eventData,
        selectOpen: cell.selectOpen === cell.selectKey ? undefined : cell.selectKey,
      },
      enableEditMode: false,
    };
  }

  render(
    cell: Compatible<DropdownCell>,
    isInEditMode: boolean,
    onCellChanged: (cell: Compatible<DropdownCell>, commit: boolean) => void,
  ): React.ReactNode {
    return (
      <DropdownInput
        onCellChanged={cell => onCellChanged(this.getCompatibleCell(cell), true)}
        cell={cell}
        isInEditMode={isInEditMode}
      />
    );
  }
}

interface DIProps {
  onCellChanged: (...args: any[]) => void;
  cell: Record<string, any>;
  isInEditMode: boolean;
}

const DropdownInput: FC<DIProps> = ({ onCellChanged, cell, isInEditMode }) => {
  const { selectOpen, selectKey } = cell;

  const selectRef = useRef<any>(null);

  const [inputValue, setInputValue] = useState<string | undefined>(cell.inputValue);
  const selectedValue = useMemo<OptionType | undefined>(
    () => cell.values.find((val: any) => val.value === cell.text),
    [cell.text, cell.values],
  );

  useEffect(() => {
    if (selectOpen === selectKey && selectRef.current) {
      selectRef.current.focus();
      setInputValue(cell.inputValue);
    }
  }, [selectOpen, selectKey, cell.inputValue]);

  return (
    <div
      style={{ width: '100%' }}
      onPointerDown={e => onCellChanged({ ...cell, selectOpen: selectKey })}
    >
      <Select
        {...(cell.inputValue && {
          inputValue,
          defaultInputValue: inputValue,
          onInputChange: e => setInputValue(e),
        })}
        placeholder="Выбрать"
        isSearchable={true}
        ref={selectRef}
        {...(selectOpen !== undefined && { menuIsOpen: selectOpen === selectKey })}
        onMenuClose={() =>
          onCellChanged({
            ...cell,
            selectOpen: selectOpen === selectKey ? undefined : selectKey,
            inputValue: undefined,
          })
        }
        onMenuOpen={() => {
          isInEditMode && onCellChanged({ ...cell, selectOpen: selectKey });
        }}
        onChange={e =>
          onCellChanged({
            ...cell,
            selectedValue: (e as OptionType).value,
            selectKey: undefined,
            inputValue: undefined,
          })
        }
        blurInputOnSelect={true}
        defaultValue={selectedValue}
        value={selectedValue}
        isDisabled={cell.nonEditable}
        options={cell.values}
        onKeyDown={e => {
          e.stopPropagation();

          if (e.key === 'Escape') {
            selectRef.current.blur();
            return onCellChanged({ ...cell, selectKey: undefined, inputValue: undefined });
          }
        }}
        components={{
          Option: CustomOption,
          Menu: CustomMenu,
        }}
        styles={{
          container: provided => ({
            ...provided,
            width: '100%',
            height: '100%',
            ...cell.styles?.container,
          }),
          control: provided => ({
            ...provided,
            border: 'none',
            borderColor: 'transparent',
            minHeight: '25px',
            background: 'transparent',
            boxShadow: 'none',
            ...cell.styles?.control,
          }),
          indicatorsContainer: provided => ({
            ...provided,
            paddingTop: '0px',
            ...cell.styles?.indicatorsContainer,
          }),
          dropdownIndicator: provided => ({
            ...provided,
            padding: '0px 4px',
            ...cell.styles?.dropdownIndicator,
          }),
          singleValue: provided => ({
            ...provided,
            color: 'inherit',
            ...cell.styles?.singleValue,
          }),
          indicatorSeparator: provided => ({
            ...provided,
            marginTop: '4px',
            marginBottom: '4px',
            ...cell.styles?.indicatorSeparator,
          }),
          input: provided => ({
            ...provided,
            padding: 0,
            color: '#FFFFFF',
            ...cell.styles?.input,
          }),
          valueContainer: provided => ({
            ...provided,
            padding: '0 8px',
            ...cell.styles?.valueContainer,
          }),
        }}
      />
    </div>
  );
};

const CustomOption: React.FC<OptionProps<OptionType, false>> = ({
  innerProps,
  label,
  isSelected,
  isFocused,
  isDisabled,
}) => (
  <div
    {...innerProps}
    onPointerDown={e => e.stopPropagation()}
    className={`rg-dropdown-option${isSelected ? ' selected' : ''}${isFocused ? ' focused' : ''}${isDisabled ? ' disabled' : ''}`}
  >
    {label}
  </div>
);

const CustomMenu: React.FC<MenuProps<OptionType, false>> = ({ innerProps, children }) => (
  <div {...innerProps} className="rg-dropdown-menu" onPointerDown={e => e.stopPropagation()}>
    {children}
  </div>
);
